<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        L’impronta dell’azienda è basata sulla sinergia da parte dei professionisti, che affiancano il cliente al fine di realizzare un progetto formativo su misura in base alle proprie esigenze. Si offre la possibilità di svolgere i corsi in sede, videoconferenza ed on-line.
                    </p>
                    <ul class="social-link d-none">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.formatiora.cloud/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.formatiora.cloud/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via I Gelsi, 1.21<br>80014 Napoli (Na)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+3908119337958">Telefono: (+39) 081-19337958</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:segreteria@formatiora.com">segreteria@formatiora.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+3908119337958">Lunedì-Venerdì: 09:00÷18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Formatiora Sicurezza e Formazione - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2 d-none">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
