<div class="row align-items-center">
    <div class="col-lg-3"></div>
    <div class="col-lg-3 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/ebin.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-3 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/iso.jpg" alt="image">
        </div>
    </div>

</div>
